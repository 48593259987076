import React, { useEffect, useState, } from "react";
import { FormattedMessage, } from "react-intl";
import { useLocalStorage } from 'usehooks-ts';
import { Info, } from '@phosphor-icons/react';

import { FormatEuro, optionPricing, } from '../../../components/helpers';
import ArrangementInfoModal from '../../../components/modals/arrangement.info.modal';

import { Image, } from "antd";
import PlaceHolder from '../../../assets/images/placeholder.png';

const HomeArrangementView = ({
    productData,
    history,
    options,
    ...props
}) => {

    const [possibleOptions, setPossibleOptions] = useState([]);

    useEffect(() => {

        // get only the posible options (get from product category cat_options)
        var possible_options = [];

        productData.product_category?.cat_options?.map((item, index) => {
            var option = options.find((element) => {
                return element.product_id === item && element.product_enabled;
            });

            if (option) {
                // if product advanced, only the one time items are possible
                // if (productData.product_data?.product_type === "PRODUCT_ADVANCED" && option.product_pricing.product_one_time_item) {
                // option.parent_product_data = productData;
                possible_options.push(option);
                // }

                // if (productData.product_data?.product_type === "PRODUCT") {
                // option.parent_product_data = productData;
                // possible_options.push(option);
                // }
            }
            setPossibleOptions(possible_options);
            return true;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {possibleOptions.map((item, index) => (
                <OptionCard
                    key={index}
                    optionData={item}
                    productData={productData}
                    {...props}
                />
            ))}
        </React.Fragment>
    );
};

export default HomeArrangementView;


const OptionCard = ({
    optionData,
    productData,
    ...props
}) => {

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [optionAmount, setOptionAmount] = useState(0);
    const { language, triggerShoppingCart, themeData: { branding_financial } } = props;
    const [shoppingCart, setShoppingCart] = useLocalStorage('shoppingcart_sloep', []);

    // create record object needed for calulation pricing / deposit
    const record = {
        date_range: [new Date(productData.date_range[0]), new Date(productData.date_range[1])],
        days_free_of_charge: [],
        bookdetail_amount: productData.bookdetail_amount,
        minimal_24hours_order: branding_financial.minimal_24hours_order,
        option_data: optionData,
        parent_product_data: productData,
    };

    // check the status for the option
    useEffect(() => {
        const cart = shoppingCart;      // copy current shoppingcart
        // get the current row from the cart
        const cart_index = cart.findIndex((item) => productData.product_data.product_id === item.product_data?.product_id);
        const cart_item = cart[cart_index];

        // check if option has already added to row selected_options to get the amount
        const selected_option = cart_item?.selected_options?.find((item) => optionData.product_id === item.product_id);
        setOptionAmount(selected_option?.bookdetail_amount || 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shoppingCart]);

    const changeOptionSelection = (e) => {
        const cart = shoppingCart;      // copy current shoppingcart
        const amount = e.target.value;  // set amount from the pulldown

        // getting the correct row from cart
        const update_cart_index = cart.findIndex((item) => productData.product_data.product_id === item.product_data?.product_id);
        const cart_item = cart[update_cart_index];

        // check if option has already added to row selected_options
        const edit_selected_option = cart_item?.selected_options?.find((item) => optionData.product_id === item.product_id);

        if (edit_selected_option) {
            // update value in existing object
            edit_selected_option.enable = amount > 0 ? true : false;
            edit_selected_option.bookdetail_amount = amount;
            // edit_selected_option.price = optionPricing(record).price_per_interval;
            // edit_selected_option.product_allow_overbooking = optionData.product_allow_overbooking;
            // edit_selected_option.product_vat_group = optionData?.product_pricing?.product_vat_group;
        } else {
            // item does not exist, create new object
            if (!cart_item.hasOwnProperty('selected_options')) {
                cart_item.selected_options = [];
            }
            cart_item.selected_options.push({
                enable: amount > 0 ? true : false,
                price: optionPricing(record).price_per_interval,
                label: optionData.product_names?.[`product_name_${language}`] || optionData.product_names?.product_name_nl,
                product_allow_overbooking: optionData?.product_allow_overbooking,
                product_vat_group: optionData?.product_pricing?.product_vat_group,
                product_one_time_item: optionData.product_pricing.product_one_time_item,
                product_id: optionData.product_id,
                bookdetail_amount: amount,
            });
        }

        // set the localstore
        triggerShoppingCart();
        setShoppingCart(cart);
    };

    return (

        <div className="arrangement-list__item display-flex flex-2 tablet-width-full calc-gap-l bg-white radius-m padding-s flex-between flex-center-bottom hover-border-primary">

            {/* Image header */}
            <div className="arrangement-list__item_image display-flex gap-s flex-2 phone-m-width-full">
                <Image src={optionData.product_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${optionData.product_images[0].image_s3_key}` : PlaceHolder}
                    width={100}
                    preview={false}
                    // style={{ display: "inline" }}
                    // rootClassName="product-cover-image"
                    className="radius-m"
                />

                <div className="list__item_description">
                    <h3 className="content-title default-text weight-semibold uppercase margin-b-s">
                        {optionData.product_names[`product_name_${language}`] || optionData.product_names.product_name_nl}
                    </h3>
                    <p className="content-description primary-color weight-semibold size-body-s">
                        <FormatEuro amount={optionPricing(record).price_per_interval} />
                        {/* {optionData.product_pricing.product_one_time} */}
                        {/* <small>iets</small> */}
                    </p>
                </div>
            </div>

            <div className="arrangement-list__item_content flex-2 phone-m-width-full">
                <select name="aantal_3"
                    onChange={changeOptionSelection}
                    value={optionAmount}
                    className="width-100 input-form weight-regular default-color margin-b-m hover-border-primary phone-m-select-field">
                    <option value="0"><FormattedMessage id="choose-amount" /></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                </select>

                <p
                    onClick={e => setOpenInfoModal(true)}
                    className="content-description display-flex flex-vertical-center align-right gap-xs show-list__item-popup cursor-pointer open-popup_btn" data-popup_id="more-info-popup">
                    <span className="text-cta-color size-body-xs"><FormattedMessage id="more-information" />
                    </span>
                    {/* <Info size={20} className="rounded-icon  bg-darkgrey white-color" /> */}
                    <span className="flex-5p">
                        <Info size={20} className="bg-secondary white-color rounded-icon" />
                    </span>

                </p>
            </div>

            {openInfoModal && (
                <ArrangementInfoModal
                    modalIsOpen={openInfoModal}
                    modalIsOpenHandler={setOpenInfoModal}
                    productData={optionData}
                />
            )}

        </div>
    );
};