import React, { useEffect, useState, } from "react";
import { FormattedMessage, } from "react-intl";
import { Image, } from "antd";
import { useLocalStorage } from 'usehooks-ts';

import { LoadingOutlined, } from '@ant-design/icons';
import { GetOptions, GetWebshopCategories, } from '../../../services/products';
import HomeArrangementView from './home.arrangement.view';
import ArrangementOrderModal from '../../../components/modals/arrangement.order.modal';
import PlaceHolder from '../../../assets/images/placeholder.png';

window.scrollTo({ top: 0 });

// Select the arragements
const HomeForm2 = ({
    history,
    ...props
}) => {

    const [options, setOptions] = useState(undefined);
    const [categories, setCategories] = useState(undefined);
    const [loadingOptionData, setLoadingOptionData] = useState(true);
    const [loadingCategoryData, setLoadingCategoryData] = useState(true);
    const { language, } = props;
    const [shoppingCart] = useLocalStorage('shoppingcart_sloep', []);

    useEffect(() => {
        getOptions();
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (options) {
            setLoadingOptionData(false);
        }

        if (categories) {
            setLoadingCategoryData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, categories,]);

    useEffect(() => {
        if (!loadingOptionData && !loadingCategoryData) {
            // if both are empty, redirect right away
            if (options.length === 0 && categories.length === 0) {
                console.warn('categories: ', categories);
                console.warn('options: ', options);
                console.warn("No options and categories found, go the next step.");
                history('/registreren');
                // setLoadingOptionData(false);
                // setLoadingCategoryData(false);
            }

            // option could be have any items but is not in the category of selected products
            // if (options.length > 0) {
            //     // check if option is in category of selected products
            //     const check_options = shoppingCart.map(item =>
            //         item.product_category?.cat_options?.map(optionItem =>
            //             options.find(element => element.product_id === optionItem && element.product_enabled)
            //         ).filter(option => option !== undefined) // Filter out undefined values
            //     ).filter(option => option !== undefined && option.length > 0); // Further filter out undefined or empty arrays

            //     if (check_options.length === 0) {
            //         console.warn('check_options: ', check_options);
            //         console.warn("No options are connected to a categorie, go the next step.");
            //         history('/registreren');
            //     }
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingOptionData, loadingCategoryData]);

    const getOptions = () => {
        GetOptions(history).then(response => {
            if (response.status) {
                setOptions(response.data.options);
            } else {
                setOptions([]);
            }
        });
    };

    const getCategories = () => {
        GetWebshopCategories(history).then(response => {
            if (response.status) {
                setCategories(response.data.categories);
            } else {
                setCategories([]);
            }
        });
    };

    return (
        <React.Fragment>

            <section className="column-container sloep-form-steps third-step">
                <div className="column-container-wrapper">
                    <div className="col-full">
                        <h2
                            className="content-title weight-bold default-color uppercase margin-b-xxl phone-m-display-flex">
                            <span className="display-inline-flex flex-center-center border-rounder bg-secondary radius-round step-number-wrapper">
                                <i className="step-number">2</i>
                            </span>

                            <span className="default-color size-h2 weight-bold flex-auto flex-center-center">
                                <FormattedMessage id="arrangements" />
                            </span>
                        </h2>
                    </div>
                </div>

                {/* Arrangement / options container */}
                <div className="column-container-wrapper">

                    {/* Vul koelbox container */}
                    {loadingCategoryData && loadingOptionData && (
                        <div className="text-center align-items-center">
                            <LoadingOutlined style={{ fontSize: "50px", margin: "50px" }} />
                        </div>
                    )}

                    {!loadingCategoryData && categories.map((category, index) => (
                        <WebshopCategoryCard
                            key={index}
                            data={category}
                            history={history}
                            // shoppingCartID={item.unique_id}
                            {...props}
                        />
                    ))}

                    {!loadingOptionData && shoppingCart.map((item, index) => (
                        <React.Fragment key={index}>
                            {/* Show only the main products, webshop items in cart skipped */}
                            {item.product_data.product_type === "PRODUCT" && (
                                <>
                                    <div className="col-full margin-b-xxl">
                                        <h3 className="content-title size-h4 primary-color uppercase weight-bold">
                                            <FormattedMessage id="our-arrangements-for" /> {item.product_data.product_names[`product_name_${language}`] || item.product_data.product_names.product_name_nl}
                                        </h3>
                                    </div>

                                    {/* Options / arrangement list */}
                                    <div className="col-full margin-b-xxl">
                                        <div className="third-step__arrangement-list display-flex gap-m">

                                            {loadingOptionData ? (
                                                <div className="text-center align-items-center">
                                                    <LoadingOutlined style={{ fontSize: "50px", margin: "50px" }} />
                                                </div>
                                            ) : (
                                                <>
                                                    {/* Create the arrangement view */}
                                                    <HomeArrangementView
                                                        key={index}
                                                        productData={item}
                                                        options={options}
                                                        {...props}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>

                {/* Button containers */}
                <div className="column-container-wrapper second-step__cta-na">
                    {!loadingCategoryData && !loadingOptionData && (
                        <div className="col-full display-flex flex-between hide-tablet">

                            <button type="button"
                                onClick={e => history('/')}
                                className="slv-button step__cta-nav-btn next-btn bg-secondary hover-bg-darkblue white-color weight-semibold  margin-b-m"
                                data-nav_step="2">
                                <FormattedMessage id="previous" />
                            </button>

                            <button type="button"
                                onClick={e => history('/registreren')}
                                className="slv-button step__cta-nav-btn bg-secondary hover-bg-darkblue white-color weight-semibold margin-b-m next-nav-btn"
                                data-nav_step="4">
                                <FormattedMessage id="next" />
                            </button>

                        </div>
                    )}
                </div>

            </section>

        </React.Fragment>
    );
};

export default HomeForm2;

const WebshopCategoryCard = ({
    data,
    history,
    ...props
}) => {

    const [openOrderModal, setOpenOrderModal] = useState(false);
    const { language, } = props;

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    return (
        <div className="col-full margin-b-xxl">
            <h3 className="content-title size-h4 primary-color uppercase weight-bold">
                {data.cat_names[`cat_name_${language}`] || data.cat_names.cat_name_nl}
            </h3>

            <div
                className="hover-border-primary third-step__koelbox display-flex flex-between flex-vertical-center bg-white padding-s radius-m">
                <div className="koelbox__image flex-35 tablet-width-semifull phone-l-width-full">
                    <Image
                        src={data.cat_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data.cat_images[0].image_s3_key}` : PlaceHolder}
                        alt=""
                        className="radius-m"
                        width={280}
                    />
                </div>

                <div className="koelbox__content flex-60 tablet-width-semifull phone-l-width-full">
                    <h3 className="content-title default-color weight-semibold">
                        {data.cat_names[`cat_name_${language}`] || data.cat_names.cat_name_nl}
                    </h3>

                    <div className="content-description margin-b-xl">
                        {renderHTMLContent(data.cat_descriptions[`cat_description_${language}`] || data.cat_descriptions.cat_description_nl)}
                    </div>

                    {/* <div className="koelbox__inputs display-flex margin-b-xl">
                        <div className="koel_box__inputs-wrapper phone-m-width-ful margin-b-m">
                            <input type="radio" id="with" name="koelbox_option"
                                className="hide input-form"
                                value={true}
                            />
                            <label htmlFor="with" className="cursor-pointer"><span>
                                Koelbox vullen v.a. 5,00
                            </span></label><br />
                        </div>

                        <div className="koel_box__inputs-wrapper phone-m-width-full margin-b-m">
                            <input type="radio" id="without" className="hide input-form"
                                name="koelbox_option" value={false} defaultChecked={true} />
                            <label htmlFor="without" className="cursor-pointer"><span>
                                niet vullen
                            </span></label><br />
                        </div>
                    </div> */}

                    <div className="col-full display-flex">
                        <button type="button"
                            className="slv-button bg-secondary hover-bg-darkblue white-color weight-semibold open-popup_btn"
                            onClick={e => { setOpenOrderModal(true) }}>
                            <FormattedMessage id="compile" />
                        </button>
                    </div>

                </div>
            </div>

            {openOrderModal && (
                <ArrangementOrderModal
                    modalIsOpen={openOrderModal}
                    modalIsOpenHandler={setOpenOrderModal}
                    categoryID={data.cat_id}
                    history={history}
                    name={data.cat_names[`cat_name_${language}`] || data.cat_names.cat_name_nl}
                    {...props}
                />
            )}
        </div>
    );
};