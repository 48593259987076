import React, { useEffect, useState, } from "react";
// import { Link, } from 'react-router-dom';
import { FormattedMessage, FormattedNumber, } from "react-intl";
import { useLocalStorage } from 'usehooks-ts';
import { Empty, } from 'antd';
import { Pen, Trash, } from '@phosphor-icons/react';
import { FormatEuro, calculateTotal, calculateDeposit, } from './../../../components/helpers';
import ArrangementOrderModal from '../../../components/modals/arrangement.order.modal';
import Moment from 'react-moment';
import 'moment-timezone';
import PlaceHolder from '../../../assets/images/placeholder.png';

const HomeShoppingListView = ({
    // couponCodeStatus = { status: false, data: {} },
    customerData = {},
    history,
    formStep,
    shoppingListRef,
    ...props
}) => {

    const [isWebshopItemsVisible, setIsWebshopItemsVisible] = useState(false);
    const [groupedWebshopItems, setGroupedWebshopItems] = useState([]);
    const [cartTotal, setCartTotal] = useState({ deposit_total_price: 0, sub_total_price: 0, total_vat: {} });
    const [vatRate, setVatRate] = useState(undefined);
    const { triggerShoppingCart, } = props;
    const { branding_financial, } = props.themeData;
    const { prices_include_vat, } = branding_financial;
    const isVatShifted = customerData.customer_data?.customer_vat_shifted || false;
    const [shoppingCart, setShoppingCart] = useLocalStorage('shoppingcart_sloep', []);
    const [couponCodeStatus] = useLocalStorage('coupon_code_sloep', { status: false, data: {} });

    useEffect(() => {
        groupWebshopItems();
        // eslint-disable-next-line
    }, [shoppingCart]);

    useEffect(() => {
        const { vat_rates, } = branding_financial;
        if (vat_rates) { setVatRate(vat_rates); }
        // eslint-disable-next-line
    }, [branding_financial]);

    useEffect(() => {
        calculateCartTotal();
        showWebshopItems();

        // eslint-disable-next-line
    }, [shoppingCart, vatRate]);

    const calculateCartTotal = () => {
        // setLoadingCartTotal(true);
        let sub_total_price = 0;
        let deposit_total_price = 0;
        let total_vat = {};

        for (let index = 0; index < shoppingCart.length; index++) {
            const record = shoppingCart[index];
            record.date_range = [new Date(record.date_range[0]), new Date(record.date_range[1])];
            const results = calculateTotal(record, vatRate, couponCodeStatus.data);
            const deposit = calculateDeposit(record);
            deposit_total_price += deposit;

            // Merge new vat into existing total_vat object
            for (const [key, value] of Object.entries(results.total_vat)) {
                if (total_vat.hasOwnProperty(key)) {
                    total_vat[key] += value;
                } else {
                    total_vat[key] = value;
                }
            };

            sub_total_price += results.total_price;
        };

        setCartTotal({ deposit_total_price, sub_total_price, total_vat });
        // setLoadingCartTotal(false);
    };

    const cartTotalPrice = () => {

        if (prices_include_vat) {
            if (couponCodeStatus.checked && couponCodeStatus.status) {
                let tmp_total = 0;
                if (couponCodeStatus.data.coupon_discount_type === "AMOUNT") {
                    // tmp_total = cartTotal.deposit_total_price + cartTotal.sub_total_price - couponCodeStatus.data.coupon_discount;
                    tmp_total = cartTotal.sub_total_price - couponCodeStatus.data.coupon_discount;
                } else {
                    // tmp_total = cartTotal.deposit_total_price + cartTotal.sub_total_price - (cartTotal.sub_total_price * couponCodeStatus.data.coupon_discount / 100);
                    tmp_total = cartTotal.sub_total_price - (cartTotal.sub_total_price * couponCodeStatus.data.coupon_discount / 100);
                }
                if (tmp_total < 0) {
                    return 0;
                } else {
                    return tmp_total;
                }
            } else {
                // return cartTotal.deposit_total_price + cartTotal.sub_total_price;
                return cartTotal.sub_total_price;
            }

        } else {
            var total_vat = 0;
            Object.keys(cartTotal.total_vat).map((item, index) => (
                total_vat = total_vat + cartTotal.total_vat[item]
            ));

            if (isVatShifted) {
                return cartTotal.deposit_total_price + cartTotal.sub_total_price;
            } else {
                return total_vat + cartTotal.deposit_total_price + cartTotal.sub_total_price;
            }

        }
    };

    const handleDelete = (key) => {
        const cart = shoppingCart.filter((item) => item.unique_id !== key);
        setShoppingCart(cart);
        triggerShoppingCart(undefined);
    };

    const nextStepHandler = () => {

        switch (formStep) {
            case 1:
                history("/arrangementen");
                break;
            case 2:
                history("/registreren");
                break;

            default:
                break;
        }
    };

    const showWebshopItems = () => {
        setIsWebshopItemsVisible(false);

        for (let index = 0; index < shoppingCart.length; index++) {
            const record = shoppingCart[index];
            if (record.product_data.product_type === "SHOP") {
                setIsWebshopItemsVisible(true);
                break;
            }
        }

    };

    const groupWebshopItems = () => {
        const webshopItems = [];
        let categoryID = undefined;

        for (let index = 0; index < shoppingCart.length; index++) {
            const record = shoppingCart[index];
            let tmp = {};

            if (categoryID !== record.category_id && record.product_data.product_type === "SHOP") {
                categoryID = record.category_id;
                const itemsWithSameCategory = shoppingCart.filter(item => item.category_id === record.category_id);
                tmp.items = itemsWithSameCategory;
                tmp.category_name = record.category_name;
                tmp.category_id = record.category_id;
                webshopItems.push(tmp);
            }
        }
        setGroupedWebshopItems(webshopItems);

    };

    return (
        <section className="column-container overview bg-white radius-l padding-m margin-b-xl">
            {/* shoppingCart order */}
            <div className="column-container-wrapper" ref={shoppingListRef}>
                <div className="col-full">
                    <h2 className="content-title weight-bold uppercase default-color">
                        <FormattedMessage id="your-order" />
                    </h2>
                </div>
            </div>

            {/* Empty list item */}
            {shoppingCart?.length === 0 && (
                <div className=""
                    style={{ textAlign: "center", height: "70vh" }}>
                    <Empty
                        description={
                            <span>
                                <FormattedMessage id="shoppinglist-empty" />
                            </span>
                        }
                    />
                </div>
            )}

            {/* Item cards in shoppingCart */}
            {shoppingCart?.map((item, index) => (
                <React.Fragment key={index}>
                    {item.product_data.product_type === "PRODUCT" && (
                        <>
                            <div
                                key={index}
                                className="column-container-wrapper display-flex gap-s border-bottom-dotted">
                                <CartItem
                                    data={item}
                                    handleDelete={handleDelete}
                                    vatRate={vatRate}
                                    {...props} />
                            </div>

                            <div className="column-container-wrapper arrangement__overview">
                                {item.selected_options?.map((option, index) => (
                                    <div key={index}>
                                        {option.enable && (
                                            <div
                                                className="col-full arrangement__overview_item border-bottom-dotted">
                                                <h3
                                                    className="content-title size-body weight-semibold default-color padding-tb-s margin-b-0">
                                                    Inclusief: {option.label}
                                                </h3>

                                                {/* {option.product_one_time_item && (<small className="ms-1">Eenmalig</small>)} */}
                                                <p><FormatEuro amount={option.price} /> (<small><FormattedMessage id="amount" />: {option.bookdetail_amount}</small>)</p>
                                            </div>
                                        )}
                                    </div>
                                ))}

                            </div>
                        </>)}
                </React.Fragment>
            ))}

            {/* webshop products / arrangement view  */}
            {isWebshopItemsVisible && (
                <div className="column-container-wrapper dranken__container">
                    <div className="col-full padding-tb-s">
                        <label htmlFor="dranken"
                            className="content-title size-body weight-semibold display-flex flex-between margin-b-l cursor-pointer">
                            <span
                                className="weight-bold primary-color">
                                <FormattedMessage id="arrangements" />
                            </span>
                        </label>

                        {groupedWebshopItems?.map((item, index) => (
                            <React.Fragment key={`${item.category_id}-${item.category_name}`}>
                                <ArrangementItem
                                    data={item}
                                    shoppingCart={shoppingCart}
                                    setShoppingCart={setShoppingCart}
                                    {...props} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}

            {/* Total overview specifications */}
            {shoppingCart?.length > 0 && (
                <React.Fragment>

                    <div className="column-container-wrapper total__container display-flex flex-between my-1"
                        id="total__container">
                        <div className="col-lef ">
                            <h3 className="content-title size-body weight-semibold default-color">
                                <FormattedMessage id="shoppinglist-overview" />
                            </h3>
                        </div>
                        <div className="col-right">
                            {/* <p className="content-title size-body weight-semibold">200,00</p> */}
                        </div>
                    </div>

                    {/* totals from shoppingCart */}
                    <div className="column-container-wrapper breakdown__container">
                        <div className="col-full bg-tertiary p-2 radius-m">

                            {/* Netto total */}
                            <div className="breakdown__container_item display-flex flex-between padding-tb-xs">
                                <p>
                                    <FormattedMessage id="costs-total" />
                                </p>
                                <p><FormatEuro amount={cartTotal.sub_total_price} /></p>
                            </div>

                            {/* Deposit price */}
                            {/* {cartTotal.deposit_total_price > 0 && (
                                <div className="breakdown__container_item display-flex flex-between padding-tb-xs">
                                    <p>
                                        <FormattedMessage id="cost-deposit" />
                                    </p>
                                    <p><FormatEuro amount={cartTotal.deposit_total_price} /></p>
                                </div>
                            )} */}

                            {/* Discount */}
                            {couponCodeStatus.checked && couponCodeStatus.status && (
                                <div className="breakdown__container_item display-flex flex-between padding-tb-xs">
                                    <p>
                                        <FormattedMessage id="discount" />
                                        {couponCodeStatus.data.coupon_discount_type === "PERCENTAGE" && (
                                            <span> {couponCodeStatus.data.coupon_discount}%</span>
                                        )}
                                    </p>
                                    <p>
                                        {/* <s>€ 5,00</s> € 0,00 */}
                                        {couponCodeStatus.data.coupon_discount_type === "AMOUNT" ? (
                                            <FormatEuro amount={(couponCodeStatus.data.coupon_discount) * -1} />
                                        ) : (
                                            <FormatEuro amount={(cartTotal.sub_total_price * couponCodeStatus.data.coupon_discount / 100) * -1} />
                                        )}

                                    </p>
                                </div>
                            )}

                            {/* Vat details */}
                            {isVatShifted ? (
                                <div className="breakdown__container_item display-flex flex-between padding-tb-xs">
                                    <p>
                                        <FormattedMessage id="vat-shifted" />
                                    </p>
                                </div>
                            ) : (
                                <>
                                    {Object.keys(cartTotal.total_vat).map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div className="breakdown__container_item display-flex flex-between padding-tb-xs">
                                                <p>
                                                    {prices_include_vat ? (
                                                        <span><FormattedMessage id="vat-included" /> ({item} %)</span>
                                                    ) : (
                                                        <span><FormattedMessage id="total-vat" /> ({item} %)</span>
                                                    )}
                                                </p>
                                                <p><FormatEuro amount={cartTotal.total_vat[item]} /></p>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </>
                            )}

                            {/* Total */}
                            <div className="breakdown__container_item display-flex flex-between padding-tb-xs">
                                <h3>
                                    <FormattedMessage id="total" />
                                </h3>
                                <h3><FormatEuro amount={cartTotalPrice()} /></h3>
                            </div>
                        </div>
                    </div>

                    {/* Desktop button */}
                    <div className="column-container-wrapper second-step__cta-nav hide-tablet padding-tb-m">
                        <div className="col-full display-flex align-right">

                            <button type="button"
                                hidden={formStep === 3}
                                onClick={e => history('/registreren')}
                                className="slv-button hiden m-2 step__cta-nav-btn transparent-btn bg-transparent weight-semibold">
                                <FormattedMessage id="pay-and-reserve" />
                            </button>

                            <button type="button"
                                hidden={formStep === 3}
                                onClick={e => nextStepHandler()}
                                className="slv-button m-2 step__cta-nav-btn bg-primary white-color weight-semibold bg-secondary">
                                <FormattedMessage id="next" />
                            </button>

                        </div>
                    </div>

                    {/* Mobile buttons sticky on bottom, do not show in last step. */}
                    {formStep !== 3 && (
                        <div className="col-full flex-between mobile-floating-cta hide show-flex-tablet">
                            <button type="button"
                                onClick={e => shoppingListRef.current.scrollIntoView({ behavior: 'smooth' })}
                                className="slv-button bg-transparent default-color weight-semibold mx-1 receipt-btn">
                                <FormattedMessage id="shoppinglist" />
                            </button>

                            <button type="button"
                                onClick={e => nextStepHandler()}
                                className="slv-button step__cta-nav-btn book-btn bg-secondary white-color weight-semibold mx-1">
                                <FormattedMessage id="next" />
                            </button>
                        </div>
                    )}


                </React.Fragment>
            )}
        </section>
    );
};

export default HomeShoppingListView;


const CartItem = ({
    data,
    handleDelete,
    vatRate,
    ...props
}) => {

    const { language, } = props;

    const getPrice = () => {
        data.interval_amount = parseFloat(data.interval_amount) || 1;
        data.selected_options = data.selected_options || [];
        data.possible_options = data.possible_options || [];
        data.date_range = [new Date(data.date_range[0]), new Date(data.date_range[1])];
        const price = calculateTotal(data, vatRate);
        return price.total_price;
    };

    return (

        <React.Fragment>
            {/* Header image */}
            <div className="col-left flex-30 flex-center-center">
                <img src={data.product_data.product_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data.product_data.product_images[0].image_s3_key}` : PlaceHolder}
                    className="radius-s _xxsmall"
                    alt={data.product_data.product_names.product_name_nl} />

                {/* <img src="custom-assets/image2.png" alt="" className="radius-s _xxsmall" /> */}
            </div>

            {/* Cart details */}
            <div className="col flex-2">
                <h3 className="content-title weight-semibold size-body margin-b-s">
                    {data.product_data?.product_names?.[`product_name_${language}`] || data.product_data?.product_names?.product_name_nl} (<FormattedMessage id={`${data.bookdetail_amount}-sloop`} />)
                </h3>

                {/* <p className="content-description">
                    {data.product_data.product_custom_fields.find(p => p.field_placeholder === "aantal_personen")?.field_value} <FormattedMessage id="personen" /> (<FormattedMessage id={`${data.bookdetail_amount}-sloop`} />)
                </p> */}

                <p className="content-description">
                    <FormattedNumber value={data.interval_amount} />  <FormattedMessage id="hour-sail" />
                </p>
                <p className="content-description">
                    <Moment tz="Europe/Amsterdam" format="DD-MM-YYYY" date={data.date_range[0]} /> om {data.start_time}
                </p>

                {/* {calculateTotal(data)} */}
                <FormatEuro amount={getPrice() || 0} />
            </div>

            <div className="col flex-5p flex-vertical-center">
                <Trash className="pointer" onClick={e => handleDelete(data.unique_id)} />
            </div>

        </React.Fragment>
    );
};

const ArrangementItem = ({
    data,
    shoppingCart,
    setShoppingCart,
    ...props
}) => {

    const [openOrderModal, setOpenOrderModal] = useState(false);
    const { history, language, } = props;

    const deleteFromCart = (key) => {
        let cart = shoppingCart;

        for (let index = 0; index < data.items.length; index++) {
            const element = data.items[index];
            cart = cart.filter((item) => item.unique_id !== element.unique_id);
        }
        setShoppingCart(cart);
    };

    const calculateTotalPrice = () => {
        let total = 0;
        data.items.map((item, index) => (
            total += item.bookdetail_amount * item.product_data.product_pricing.product_pricing[0].price
        ));
        return total;
    };

    return (
        <div className="draken__container_description bg-grey padding-s radius-m mt-3">
            <Trash onClick={e => deleteFromCart()} className="pointer ms-1" style={{ float: 'inline-end' }} />
            <Pen onClick={e => setOpenOrderModal(true)} className="pointer ms-1" style={{ float: 'inline-end' }} />
            <h3 className="content-title size-body weight-semibold">
                {data.category_name}
            </h3>
            <ul className="list-style-none" id="koelbox-list-items">
                {data.items.map((item, index) => (
                    <React.Fragment key={index}>
                        {item.bookdetail_amount > 0 && (
                            <li className="display-fle flex-betwee">
                                {item.bookdetail_amount}x {item.product_data.product_names[`product_name_${language}`] ||
                                    item.product_data.product_names.product_name_nl} <small>(<FormatEuro amount={item.product_data.product_pricing.product_pricing[0].price} />)</small>
                                <span className="float-end"><FormatEuro amount={item.product_data.product_pricing.product_pricing[0].price * item.bookdetail_amount} /></span>
                            </li>
                        )}</React.Fragment>
                ))}
                <li className="display-flex flex-between"
                    style={{ borderTop: '1px solid', fontWeight: 800 }}><span>Totaal:</span> <FormatEuro amount={calculateTotalPrice()} /></li>
            </ul>

            {openOrderModal && (
                <ArrangementOrderModal
                    modalIsOpen={openOrderModal}
                    modalIsOpenHandler={setOpenOrderModal}
                    categoryID={data.category_id}
                    history={history}
                    name={data.category_name}
                    {...props} />
            )}
        </div>
    );
};